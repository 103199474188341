import { createSlice } from "@reduxjs/toolkit";
import * as apiService from "../services/api-service";
import { toast } from "react-toastify";

const initialState = {
  data: null,
  history: [],
  planData: null,
  isGenerateTreeDialogOpen: false,
  isDeleteTreeconfirmModalOpen: false,
  userDataLoading: false,
  userData: null,
  selectedTreeId: "",
  selectedDeleteTreeId: "",
  selectedDeleteTreeName: "",
  selectedUpdateTreeId: "",
  loading: false,
  plansLoading: false,
  planGenerating: false,
  isTreeHistoryLoading: false,
  isTreeLoading: false,
  visibleRightPanel: "TreeHistory",
  totalPages: 1,
  selectedTreeNode: null,
  deleteConfirmationModalType: "",
  selectedTreePlannedNodeNames: [],
};

const isMobile = window.innerWidth < window.innerHeight

// Slice
const treeMapSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset: (state) => {
      return initialState;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setHistory: (state, action) => {
      state.history = action.payload;
    },
    setHistoryLoading: (state, action) => {
      state.isTreeHistoryLoading = action.payload;
    },
    setPlanData: (state, action) => {
      state.planData = action.payload;
    },
    setIsGenerateTreeDialogOpen: (state, action) => {
      state.isGenerateTreeDialogOpen = !state.isGenerateTreeDialogOpen;
      state.selectedUpdateTreeId = action.payload?.id;
    },
    setIsDeleteTreeconfirmModalOpen: (state, action) => {
      state.isDeleteTreeconfirmModalOpen = !state.isDeleteTreeconfirmModalOpen;
      state.deleteConfirmationModalType = action.payload?.type;
      state.selectedDeleteTreeId = action.payload?.id;
      state.selectedDeleteTreeName = action.payload?.name;
    },
    setSelectedTreeId: (state, action) => {
      state.selectedTreeId = action.payload;
    },
    setVisibleRightPanel: (state, action) => {
      state.visibleRightPanel = action.payload;
    },
    setPlanLoading: (state, action) => {
      state.plansLoading = action.payload;
    },
    setPlanGenerating: (state, action) => {
      state.planGenerating = action.payload;
    },
    setUserDataLoading: (state, action) => {
      state.userDataLoading = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setSelectedTreePlannedNodeNames: (state, action) => {
      state.selectedTreePlannedNodeNames = action.payload;
    },
    setSelectedTreeNode: (state, action) => {
      state.selectedTreeNode = action.payload;
    },
    setTreeLoading: (state, action) => {
      state.isTreeLoading = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload
    }
  },
});

// Actions
export const {
  reset,
  setData,
  setHistory,
  setIsGenerateTreeDialogOpen,
  setIsDeleteTreeconfirmModalOpen,
  setSelectedTreeId,
  setTreeLoading,
  setHistoryLoading,
  setTreeHistoryLoading,
  setVisibleRightPanel,
  setPlanLoading,
  setPlanGenerating,
  setPlanData,
  setUserDataLoading,
  setUserData,
  setSelectedTreePlannedNodeNames,
  setSelectedTreeNode,
  setLoading,
  setTotalPages,
} = treeMapSlice.actions;
export default treeMapSlice.reducer;

export const createTree = (title, event, context, requestType) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch(setTreeLoading(true));
    apiService.createTree({
      event,
      context,
      title,
      requestType,
    })
      .then((response) => {
        if (response?.error) {
          notify(response.error.code, "error");
          // reject();
        } else if (response.status === 201) {
          let historyData = getState().treeMap.history;
          let updatedHistory = [...historyData, response.data?.tree]
          dispatch(setHistory(updatedHistory));
          dispatch(setSelectedTreeId(response.data?.tree?._id));
          dispatch(setData(response.data?.tree));
          dispatch(setVisibleRightPanel("TreeHistory"));
          resolve();
        }
      })
      .catch((error) => {
        dispatch(setData(null));
        if (error?.response?.data?.code === 'ECONNRESET') {
          notify("Network error: Please check your internet connection.", "error");
        } else {
          console.log("ERROR MESSAGE", error);
          notify(error.message, "error");
        }
        reject(error);
      })
      .finally(() => {
        // dispatch(setData(getState().treeMap.data));
        dispatch(setTreeLoading(false));
      });
  });
};

export const editTree = (title, event, context, requestType, key) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch(setSelectedTreeId(key));
    dispatch(setTreeLoading(true));
    apiService.editTree({
      key,
      title,
      event,
      context,
      requestType,
    })
      .then((response) => {
        if (response?.error) {
          notify(response.error.code, "error");
          // reject();
        } else {
          let historyData = getState().treeMap.history;
          const updatedHistory = historyData.map((el) => el._id === key ? { ...el, name: event, title, context, requestType } : el)
          dispatch(setHistory(updatedHistory));
          dispatch(setSelectedTreeId(key));
          dispatch(setData(response?.data?.tree));
          dispatch(setVisibleRightPanel("TreeHistory"));
          resolve();
        }
      })
      .catch((error) => {
        dispatch(setData(null));
        if (error?.response?.data?.code === 'ECONNRESET') {
          notify("Network error: Please check your internet connection.", "error");
        } else {
          console.log("ERROR MESSAGE", error);
          notify(error.message, "error");
        }
        reject(error);
      })
      .finally(() => {
        dispatch(setData(getState().treeMap.data));
        dispatch(setTreeLoading(false));
      });
  });
};

export const getTree = (key) => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const historyData = getState().treeMap.history;
    if (historyData && getState().treeMap.selectedTreeId !== key) {
      dispatch(setLoading(true));
      dispatch(setSelectedTreeId(key));
      apiService.getTree(key)
        .then((response) => {
          if (response?.error) {
            notify(response.error.code, "error");
            dispatch(setData(null));
            // reject();
          } else if (response.status === 200) {
            dispatch(setSelectedTreePlannedNodeNames(response.data?.tree?.plans?.map((el) => el.node_name)));
            dispatch(setData(response.data?.tree));
          }
          resolve();
          dispatch(setLoading(false));
        })
        .catch((error) => {
          dispatch(setData(null));
          dispatch(setLoading(false));
          // console.log("ERROR MESSAGE", error);
          notify(error.message, "error");
        })
    }
  });
};

export const getTreeHistory = (currentPage) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(setHistoryLoading(true));
    apiService.getTreeHistory({
      currentPage
    })
      .then((response) => {
        if (response?.error) {
          notify(response.error.code, "error");
          dispatch(setHistory(null));
        } else if (response?.status === 200) {
          dispatch(setTotalPages(response.data?.paginate.totalPages))
          dispatch(setHistory(response.data?.trees));
          dispatch(setHistoryLoading(false));
        }
        resolve(response);
      }).catch(error => {
        console.log("ERROR MESSAGE", error);
        notify(error.message, "error");
      })
  })
};

export const treeHistoryRemove =
  () => async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const state = getState();
      let deleteTreeId = state.treeMap.selectedDeleteTreeId;
      apiService.removeTreeHistoryRecord({ deleteTreeId })
        .then((response) => {
          if (response?.error) {
            notify(response.error.code, "error");
          } else if (response.status === 200) {
            let updatedHistory = state.treeMap.history.filter((el) => el._id !== deleteTreeId)
            if (updatedHistory?.length >= 0) {
              dispatch(setData(null));
            }
            dispatch(setHistory(updatedHistory));
            dispatch(
              setIsDeleteTreeconfirmModalOpen({ type: "tree", id: "", name: "" })
            );
            dispatch(setSelectedTreeId(""));
            notify("Tree Removed Successfully!", "success")
          }
          resolve();
        })
        .catch((error) => {
          notify(error.message, "error");
        })
    })
  };

export const treeHistoryTitleUpdate =
  (treeHistoryId, title) => async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const state = getState();
      apiService
        .udpateTreeHistoryTitle({
          treeHistoryId,
          title,
        })
        .then((response) => {
          if (response?.error) {
            notify(response.error.code, "error");
          } else if (response.status === 200) {
            let updatedHistory = state.treeMap.history.map((el) => el._id === treeHistoryId ? { ...el, title } : el)
            dispatch(setHistory(updatedHistory));
          }
          resolve();
        })
        .catch((error) => {
          notify(error.message, "error");
        });
    });
  };

export const generatePlan = (nodeData) => (dispatch, getState) => {
  const SelectedTreePlannedNodeNames = getState().treeMap.selectedTreePlannedNodeNames;
  const selectedTreeId = getState().treeMap.selectedTreeId;

  if (SelectedTreePlannedNodeNames.includes(nodeData.data.name)) {
    return dispatch(getPlan(nodeData))
  } else return new Promise((resolve, reject) => {
    let responseData = "";
    dispatch(setSelectedTreeNode(nodeData));
    dispatch(setVisibleRightPanel("Plans"));
    dispatch(setPlanLoading(true));



    apiService.generatePlan({
      key: selectedTreeId,
      nodeName: nodeData.data.name,
    }).then(async (response) => {
      if (response?.status === 200) {
        dispatch(setSelectedTreePlannedNodeNames([...SelectedTreePlannedNodeNames, nodeData.data.name]))
        dispatch(setPlanLoading(false));
        dispatch(setPlanGenerating(true))
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        const loopRunner = true;

        while (loopRunner) {
          const { value, done } = await reader.read();
          if (done) {
            dispatch(setPlanGenerating(false))
            break;
          }
          const decodedChunk = decoder.decode(value, { stream: true });
          responseData += decodedChunk;
          dispatch(setPlanData(responseData))
        }
      }
    })
      .catch((error) => {
        dispatch(setPlanData(null));
        if (error?.response?.data?.code === "ECONNRESET") {
          notify(
            "Unable to process your request. Please check your internet connection or try again later.",
            "error"
          );
        } else {
          notify(error.message, "error");
          console.log("ERROR MESSAGE", error);
        }
        dispatch(setPlanLoading(false));
        // reject();
      });
  });
};

export const getPlan = (nodeData) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const selectedTreeId = getState().treeMap.selectedTreeId;
    dispatch(setSelectedTreeNode(nodeData));
    dispatch(setVisibleRightPanel("Plans"));
    dispatch(setPlanLoading(true));

    apiService.getPlan({
      key: selectedTreeId,
      nodeName: nodeData.data.name
    })
      .then((response) => {
        if (response?.error) {
          notify(response.error.code, "error");
        } else if (response?.status === 200) {
          dispatch(setPlanData(response.data?.result));
          dispatch(setPlanLoading(false));
        }
        resolve(response);
      }).catch(error => {
        console.log("ERROR MESSAGE", error);
        notify(error.message, "error");
      })
  })
}

export const updatePlan = (key, node_name, context) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    let responseData = "";
    dispatch(setPlanLoading(true));
    apiService.updatePlan({
      key,
      node_name,
      context
    })
      .then(async (response) => {
        if (response?.error) {
          notify(response.error.code, "error");
        } else if (response?.status === 200) {
          dispatch(setPlanLoading(false));
          dispatch(setPlanGenerating(true))
          const reader = response.body.getReader();
          const decoder = new TextDecoder();
          const loopRunner = true;

          while (loopRunner) {
            const { value, done } = await reader.read();
            if (done) {
              dispatch(setPlanGenerating(false))
              break;
            }
            const decodedChunk = decoder.decode(value, { stream: true });
            responseData += decodedChunk;
            dispatch(setPlanData(responseData))
          }
        }
        resolve();
      })
      .catch((error) => {
        dispatch(setPlanData(null));
        if (error?.response?.data?.code === "ECONNRESET") {
          notify(
            "Unable to process your request. Please check your internet connection or try again later.",
            "error"
          );
        } else {
          notify(error.message, "error");
          console.log("ERROR MESSAGE", error);
        }
        dispatch(setPlanLoading(false));
        // reject();
      });
  });
};

export const removePlan = (key, node_name) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const state = getState();
    apiService.removePlanRecord({
      key,
      node_name,
    }).then((response) => {
      if (response?.error) {
        notify(response.error.code, "error");
      } else if (response.status === 200) {
        notify("Plan Removed Successfully!", "success");
        dispatch(setPlanData(null));
        dispatch(
          setSelectedTreePlannedNodeNames(
            state.treeMap.selectedTreePlannedNodeNames.filter(
              (elm) => elm !== node_name
            )
          )
        );
        dispatch(setIsDeleteTreeconfirmModalOpen({ type: "plan" }));
        document.getElementById(`spanNum${state.treeMap.selectedTreeNode.id}`).remove();
        dispatch(setSelectedTreeNode(null));
        dispatch(setVisibleRightPanel("TreeHistory"));
      }
      resolve();
    })
      .catch((error) => {
        notify(error.message, "error");
      })
  })
};

export const notify = (title, type) => {
  let color = "#3a9c3b";
  if (type === "error") {
    color = "#d73f35";
  } else if (type === "info") {
    color = "#3498DB"
  }
  return toast(title, {
    position: "top-right",
    autoClose: type === "success" ? 1000 : 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    type,
    style: {
      background: color,
      fontFamily: "ClashDisplay-Medium",
      width: isMobile && "80vw",
      margin: isMobile ? "4vw" : 0,
      borderRadius: "4px",
    },
    theme: "colored",
  });
};
