import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from "@mui/material";
import { updatePlan } from "../../reducers/treeMapSlice";
import { IsMobile } from "../../utils/deviceType";

export default function EditPlanModal({
  editOpen,
  setEditOpen,
  selectedTreeNodeName,
}) {
  const isMobile = IsMobile();
  const dispatch = useDispatch();
  const selectedTreeId = useSelector((state) => state.treeMap.selectedTreeId);

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const { context } = data;
    if (context) {
      dispatch(updatePlan(selectedTreeId, selectedTreeNodeName, context));
      toggleDialog();
    }
  };

  const toggleDialog = () => {
    setEditOpen(!editOpen);
    setTimeout(() => {
      reset({ context: "" });
    }, 200);
  };

  const handleFieldChange = (fieldName) => {
    setError(fieldName, ""); // Clear errors on field change
  };

  return (
    <Dialog
      open={editOpen}
      onMouseDown={(e) => e.stopPropagation()}
      onClose={toggleDialog}
      sx={{
        textAlign: "center",
        backdropFilter: "blur(4px)",
        "& .MuiPaper-root": {
          borderRadius: "20px",
          padding: "0px 30px",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent",
        },
      }}
    >
      <DialogTitle
        sx={{
          fontFamily: "ClashDisplay-medium",
          padding: "24px 20px 0px",
          fontSize: "1.5rem",
        }}
      >
        Add Context
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent
          sx={{
            minWidth: "25vw",
            maxWidth: "80vw",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
          }}
        >
          <Box className="actionNameContext">
            <Controller
              control={control}
              name="context"
              rules={{
                required: "Enter context",
              }}
              render={({ field }) => (
                <TextField
                  inputProps={{
                    style: {
                      fontFamily: "ClashDisplay-Regular",
                      fontSize: isMobile ? 12 : 14,
                    },
                  }}
                  fullWidth
                  placeholder="Enter feedback to tune action plan"
                  multiline
                  minRows={4}
                  onChange={() => {
                    handleFieldChange("context");
                  }}
                  {...field}
                  helperText={errors.context?.message}
                  error={Boolean(errors.context)}
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            type="submit"
            sx={{
              backgroundColor: "#1D77B7",
              textTransform: "none",
              fontFamily: "ClashDisplay-Regular",
              margin: "20px 0px 30px 0px",
              "&:hover": {
                backgroundColor: "#1D77B7",
                boxShadow: "none",
              },
            }}
            variant="contained"
          >
            Add Context
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
