import { Box, Button, Card, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ContentCopyIcon from "../../assets/images/icons/copy.svg";
import editIcon from "../../assets/images/icons/edit.svg";
import deleteIcon from "../../assets/images/icons/deleteIcon.svg";
import {
  setVisibleRightPanel,
  setIsDeleteTreeconfirmModalOpen,
  setSelectedTreeNode,
  notify,
} from "../../reducers/treeMapSlice";
import Loader from "../Loader/Loader";
import Sparkles from "../../assets/images/icons/sparkles.svg";
import "./Plans.css";
import { IsMobile } from "../../utils/deviceType";
import { useEffect, useState, useRef } from "react";
import EditPlanModal from "./EditPlanModal";

export default function Plans({ setSidebarOpened }) {
  const [editOpen, setEditOpen] = useState(false);
  const dispatch = useDispatch();
  const isMobile = IsMobile();
  const bottomEl = useRef(null);
  const planData = useSelector((state) => state.treeMap.planData);
  const plansLoading = useSelector((state) => state.treeMap.plansLoading);
  const planGenerating = useSelector((state) => state.treeMap.planGenerating);
  const selectedTreeNode = useSelector(
    (state) => state.treeMap.selectedTreeNode
  );
  const selectedTreePlannedNodeNames = useSelector(
    (state) => state.treeMap.selectedTreePlannedNodeNames
  );

  const copyFunction = () => {
    navigator.clipboard.writeText(
      document.getElementsByClassName("card")[0].innerText
    );
    notify("Plan data copied to clipboard", "success");
  };

  const toggleOpenConfirmationModal = () => {
    dispatch(setIsDeleteTreeconfirmModalOpen({ type: "plan" }));
  };

  const renderPreloadedPlan = () => {
    return (
      <Box>
        <Box className="plan-key">Action Plan:</Box>
        {planData.action_plan.map((item, index) => (
          <Box key={index} className="plan-key-data">
            <b>{index + 1}</b> - {item}
          </Box>
        ))}
        <Box className="plan-key">Out-of-the-Box Ideas:</Box>
        {planData.out_of_the_box_ideas.map((item, index) => (
          <Box key={index} className="plan-key-data">
            <b>{index + 1}</b> - {item}
          </Box>
        ))}
      </Box>
    );
  };

  const generateStream = () => {
    const type1 = planData.indexOf("**Action Plan"),
      type2 = planData.indexOf("Action Plan:"),
      actionPlanIndex = planData.search(/"?(action[- ]plan)"?\s*:/i),
      outOfBoxIndex = planData.search(
        /"?(out[- ]of[- ]the[- ]box[- ]ideas)"?\s*:/i
      ),
      splitCharacter = (type1 > 0 || type2) > 0 ? /\d+\./ : '",';

    if (actionPlanIndex > 0) {
      const renderSection = (title, statement, startIndex, endIndex) => {
        const section = statement
          .substring(startIndex, endIndex)
          .split(splitCharacter);
        return (
          <Box>
            <Box className="plan-key">{title}</Box>
            {section.map((el, index) => {
              const sentence = el.replace(/(\d+\.)|["#*\]`,}\[\]]/g, "");
              return (
                <Box key={index} className="plan-key-data">
                  <b>
                    {index + 1} -
                    {sentence.includes(":") &&
                      sentence.substring(0, sentence.indexOf(":"))}
                  </b>
                  {sentence.includes(":")
                    ? sentence.substring(sentence.indexOf(":"))
                    : sentence}
                </Box>
              );
            })}
          </Box>
        );
      };

      const renderOutOfTheBox = () => {
        if (outOfBoxIndex > 0) {
          const outOfTheBox = planData.substring(outOfBoxIndex + 15),
            startIndex = type1 ? 12 : 10,
            endIndex =
              outOfTheBox.indexOf("```json") > 0
                ? outOfTheBox.indexOf("```json")
                : outOfTheBox.indexOf("]") > 0
                  ? outOfTheBox.indexOf("]")
                  : undefined;

          return renderSection(
            "Out-of-the-Box Ideas:",
            outOfTheBox,
            startIndex,
            endIndex
          );
        }
      };
      const renderPlanActions = () => {
        const startIndex = actionPlanIndex + (type1 ? 18 : 10),
          endIndex = outOfBoxIndex > 0 ? outOfBoxIndex : undefined;

        return renderSection("Action Plan:", planData, startIndex, endIndex);
      };

      return (
        <Box ref={bottomEl}>
          {renderPlanActions()}
          {renderOutOfTheBox()}
        </Box>
      );
    } else return <Loader planGeneratingLoader />;
  };

  // const scrollToBottom = () => {
  //   bottomEl?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  // };

  // useEffect(() => {
  //   scrollToBottom();
  // }, [planData]);

  return (
    <Box className="plans-panel">
      <Box className="plans-header">
        <IconButton
          onClick={() => (
            dispatch(setSelectedTreeNode(null)),
            isMobile && setSidebarOpened(false),
            setTimeout(
              () => {
                dispatch(setVisibleRightPanel("TreeHistory"));
              },
              isMobile ? 500 : 0
            )
          )}
          color="primary"
        >
          <ArrowBackIosIcon className="back-icon" fontSize="small" />
        </IconButton>
        <Box>{isMobile ? "Plan" : "Tree History"}</Box>
      </Box>
      <Box className="plan-title-box">
        <img
          src={Sparkles}
          alt="Sparkles"
          width={20}
          height={20}
          style={{ marginTop: "5px" }}
        />
        {selectedTreeNode ? (
          <Box className="plan-title">{selectedTreeNode?.data.name}</Box>
        ) : null}
      </Box>
      {plansLoading ? (
        <Loader
          planGeneratingLoader
          alreadyExistsPlan={selectedTreePlannedNodeNames?.includes(
            selectedTreeNode?.data.name
          )}
        />
      ) : planData ? (
        <Box>
          <Card className="card">
            <Box className="plan-section">
              {planData?.hasOwnProperty("treeId")
                ? renderPreloadedPlan()
                : generateStream()}
            </Box>
          </Card>
          {!planGenerating && (
            <>
              <Button
                onClick={() => setEditOpen(true)}
                variant="outline"
                startIcon={<img alt="EditIcon" src={editIcon} />}
                className="copy-delete-button"
              >
                Context
              </Button>
              <Button
                onClick={copyFunction}
                variant="outline"
                startIcon={<img alt="CopyIcon" src={ContentCopyIcon} />}
                className="copy-delete-button"
              >
                Copy
              </Button>
              <Button
                variant="outline"
                startIcon={<img alt="CopyIcon" src={deleteIcon} />}
                className="copy-delete-button"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleOpenConfirmationModal();
                }}
              >
                Delete
              </Button>
            </>
          )}
        </Box>
      ) : (
        <Box> No Plans generated </Box>
      )}
      <EditPlanModal
        editOpen={editOpen}
        selectedTreeNodeName={selectedTreeNode?.data.name}
        setEditOpen={setEditOpen}
      />
    </Box>
  );
}
