import React, { useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import isEmail from "is-email";
import { ReactComponent as BrandLogo } from "../assets/images/logo.svg";
import { useAuth } from "../services/auth";
import Loader from "../components/Loader/Loader";
import GoogleSignInButton from "../components/GoogleButton/GoogleSignInButton";
import { IsMobile } from "../utils/deviceType";
import { getAnalytics, logEvent } from "firebase/analytics";
import { GoogleLogin } from "@react-oauth/google";

const SignIn = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: null,
      password: null,
    },
  });
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [showPassword, setShowPassword] = useState(false);

  const isMobile = IsMobile();
  const auth = useAuth();
  const [isSigningIn, setIsSigningIn] = useState(false);
  const analytics = getAnalytics();

  useEffect(() => {
    logEvent(analytics, "Signin page loaded!");
  }, []);

  const handleSignInWithGoogle = async (accessToken) => {
    try {
      const result = await auth.signInWithGoogle(accessToken);
    } catch (error) {
      console.error("Google Sign-In Error:", error.message);
    }
  };

  const onSubmit = async (data) => {
    const { email, password } = data;
    setIsSigningIn(true);
    setErrorMessage(null);

    const result = await auth.signIn({ email, password });
    if (
      result?.response?.data?.error === "Error: Incorrect password" ||
      result?.response?.data?.error === "Error: User not found" ||
      result?.response?.data?.message === "validation error"
    ) {
      setErrorMessage("Invalid login credentials");
    } else setErrorMessage("Unable to process");
    setIsSigningIn(false);
  };

  return (
    <Box>
      {isSigningIn ? (
        <Loader />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: isMobile ? "8%" : "4rem",
            marginBottom: "2rem",
          }}
        >
          <BrandLogo />
          <Typography
            sx={{
              color: "#2d333a",
              margin: `${isMobile ? "20%" : "120px"}  0px 24px`,
              fontFamily: "ClashDisplay-Medium",
              fontSize: 32,
              textAlign: "center",
            }}
          >
            Welcome back
          </Typography>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              display: "flex",
              flexDirection: "column",
              width: "320px",
              maxWidth: "80vw",
            }}
          >
            <Controller
              control={control}
              name="email"
              rules={{
                required: "Enter an email",
                validate: {
                  validateEmail: (email) =>
                    isEmail(email) || "Enter a valid email",
                },
              }}
              render={({ field }) => (
                <TextField
                  sx={{
                    "&:hover fieldset": {
                      border: "1px solid rgba(0, 0, 0, 0.23) !important",
                      borderLeft: "3px solid #1D77B7 !important",
                    },
                    "& .Mui-focused fieldset": {
                      border: "1px solid #1D77B7 !important",
                    },
                    "& input": {
                      height: "1.5em",
                      padding: "14px",
                    },
                  }}
                  label="Email Address"
                  {...field}
                  helperText={errors.email?.message}
                  error={Boolean(errors.email)}
                  type="email"
                />
              )}
            />
            <Controller
              control={control}
              name="password"
              rules={{ required: "Enter a password" }}
              render={({ field }) => (
                <TextField
                  sx={{
                    marginTop: "12px",
                    "&:hover fieldset": {
                      border: "1px solid rgba(0, 0, 0, 0.23) !important",
                      borderLeft: "3px solid #1D77B7 !important",
                    },
                    "& .Mui-focused fieldset": {
                      border: "1px solid #1D77B7 !important",
                    },
                    "& input": {
                      height: "1.5em",
                      padding: "14px",
                    },
                  }}
                  label="Password"
                  {...field}
                  helperText={errors.password?.message}
                  error={Boolean(errors.password)}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowPassword(!showPassword);
                          }}
                          edge="end"
                          style={{ paddingRight: "10px" }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "4px",
              }}
            >
              <Box />
              <Box>
                <Typography
                  sx={{
                    fontWeight: "500",
                    color: "var(--text-primary);",
                    fontFamily: "sans-serif",
                    marginRight: "3px",
                    cursor: "pointer",
                  }}
                  variant="subtitle2"
                >
                  <NavLink
                    to="/forgot-password"
                    style={{
                      textDecoration: "none",
                      color: "var(--text-primary)",
                    }}
                  >
                    Forgot Password?
                  </NavLink>
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 2,
                mb: 0,
              }}
            >
              <Button
                variant="contained"
                type="submit"
                sx={{
                  height: 45,
                  borderRadius: "6px",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                  textTransform: "none",
                  width: "100%",
                  backgroundColor: "#1D77B7",
                  boxShadow: "none",
                  "&:hover": {
                    background: "#0f639e",
                    boxShadow: "none",
                  },
                }}
              >
                Login
              </Button>
            </Box>
            <Typography
              sx={{
                alignSelf: "center",
                fontSize: "14px",
                marginTop: "14px",
                color: "#2d333a",
              }}
            >
              Don't have an account?{" "}
              <NavLink
                to="/signup"
                style={{
                  textDecoration: "none",
                  color: "#1D77B7",
                  cursor: "pointer",
                }}
              >
                Sign up
              </NavLink>
            </Typography>
            <Box
              sx={{
                marginTop: 2,
                textAlign: "center",
                color: "var(--text-primary)",
              }}
            >
              <Typography
                sx={{
                  visibility: errorMessage ? "visible" : "hidden",
                }}
                color="error"
              >
                {errorMessage}
              </Typography>
            </Box>

            <div className="or">
              <span>Or</span>
            </div>
          </form>
          <GoogleLogin
            onSuccess={(res) => handleSignInWithGoogle(res.credential)}
            onError={(err) => setErrorMessage(err)}
            containerProps={{
              style: {
                marginTop: "10px",
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default SignIn;
