import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useAuth } from "../../services/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Box,
  Tooltip,
} from "@mui/material";
import {
  createTree,
  setIsGenerateTreeDialogOpen,
  editTree,
  setUserDataLoading,
  notify,
  setSelectedTreeId,
} from "../../reducers/treeMapSlice";
import { NavLink } from "react-router-dom";
import { treeTypes, paymentPlans } from "../../utils/constants";
import lockIcon from "../../assets/images/icons/lock.svg";
import rightIcon from "../../assets/images/icons/rightIcon.svg";
import { IsMobile } from "../../utils/deviceType";
import { gotoCustomerPortal } from "../../services/api-service";
import "./GenerateTreeDialog.css";
import { getAnalytics, logEvent } from "firebase/analytics";

export default function GenerateTreeDialog({ setSidebarOpened }) {
  const { token } = useAuth();
  const [selectedTreeType, setSelectedTreeType] = useState(treeTypes[1]);
  const [showUpgrade, setShowUpgrade] = useState(false);
  const isMobile = IsMobile();
  const dispatch = useDispatch();
  const analytics = getAnalytics();
  const isGenerateTreeDialogOpen = useSelector(
    (state) => state.treeMap.isGenerateTreeDialogOpen
  );
  const selectedUpdateTreeId = useSelector(
    (state) => state.treeMap.selectedUpdateTreeId
  );
  const treeMapHistory = useSelector((state) => state.treeMap.history);
  const userData = useSelector((state) => state.treeMap.userData);

  const currentPaymentPlan =
    paymentPlans.filter(
      (el) =>
        el.label ===
        userData?.ante_Application?.subscriptions[0].plan_lookup_key
    )[0] ?? paymentPlans[0];

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (treeMapHistory?.length && selectedUpdateTreeId) {
      const selectedTree = treeMapHistory.find(
        (el) => el._id === selectedUpdateTreeId
      );
      const event = selectedTree.title;
      const context = selectedTree.context;
      const treeType = selectedTree.requestType;
      control._formValues.context = context;
      control._formValues.event = event;
      setSelectedTreeType(
        treeTypes.find((el) => el.value == treeType) ?? treeTypes[1]
      );
    }
  }, [isGenerateTreeDialogOpen]);

  const onSubmit = async (data) => {
    const { event, context } = data;
    if (!event) {
      setError("event", {
        type: "manual",
        message: "Enter an action or event",
      });
    }
    if (!context) {
      setError("context", {
        type: "manual",
        message: "Enter context",
      });
    }
    if (userData && event && context) {
      if (selectedUpdateTreeId) {
        const selectedTree = treeMapHistory.find(
          (el) => el._id === selectedUpdateTreeId
        );
        if (
          !(
            selectedTree.title === event &&
            selectedTree.context === context &&
            selectedTree.requestType === selectedTreeType.value
          )
        ) {
          setSidebarOpened(false);
          dispatch(
            editTree(
              event,
              event,
              context,
              selectedTreeType.value,
              selectedUpdateTreeId
            )
          );
          logEvent(analytics, `Tree Regenerated Successfully!`, {
            user: userData._id,
          });
        }
      } else {
        dispatch(createTree(event, event, context, selectedTreeType.value));
        logEvent(analytics, `Tree Generated Successfully!`, {
          user: userData._id,
        });
      }
      toggleDialog();
    }
  };

  const toggleDialog = () => {
    dispatch(setIsGenerateTreeDialogOpen({ id: selectedUpdateTreeId }));
    setTimeout(() => {
      reset({
        event: "",
        context: "",
      });
      setSelectedTreeType(treeTypes[1]);
    }, 200);
  };

  const handleFieldChange = (fieldName) => {
    setError(fieldName, ""); // Clear errors on field change
  };

  const onUpdateClick = async () => {
    dispatch(setUserDataLoading(true));
    const response = await gotoCustomerPortal();
    if (response?.error) {
      notify(response.error.code, "error");
    } else {
      if (response?.url) {
        console.log(">>>userData", userData);

        if (userData?.ante_Application?.subscriptions[0]) {
          response.url =
            response?.url +
            "/subscriptions/" +
            userData?.ante_Application?.subscriptions[0].subscriptionId +
            "/update";
        }
        window.location.href = response?.url;
      }
    }
  };

  return (
    <Dialog
      open={isGenerateTreeDialogOpen}
      onClose={toggleDialog}
      sx={{
        textAlign: "center",
        backdropFilter: "blur(4px)",
        "& .MuiPaper-root": {
          borderRadius: "20px",
          padding: "0px 30px",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent",
        },
      }}
    >
      <DialogTitle
        sx={{
          fontFamily: "ClashDisplay-medium",
          padding: "24px 20px 0px",
          fontSize: "1.5rem",
        }}
      >
        {selectedUpdateTreeId ? "Regenerate Tree" : "Generate a New Tree"}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent
          sx={{
            minWidth: "25vw",
            maxWidth: "80vw",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
          }}
        >
          <div
            onMouseLeave={() => setShowUpgrade(false)}
            style={{ width: "90%", alignSelf: "center" }}
          >
            <Box
              className="treeTypesContainer"
              sx={{ display: "flex", justifyContent: "center", gap: 1 }}
            >
              {treeTypes.map((type) => (
                <Tooltip
                  placement="top"
                  key={type?.value}
                  sx={{ textTransform: "none" }}
                  title={
                    currentPaymentPlan?.value >= type?.value
                      ? ""
                      : type?.value == 2
                        ? "Explore 'Optionality' to see diverse paths and choices. Enhance decision-making with a broad view of possible outcomes."
                        : "Utilize 'Inversion' to focus on avoiding negative outcomes. This mode helps in identifying and steering clear of potential pitfalls."
                  }
                >
                  <span
                    onMouseEnter={() =>
                      !(currentPaymentPlan?.value >= type.value) &&
                      setShowUpgrade(true)
                    }
                  >
                    <Button
                      className="treeTypeOption"
                      onClick={() => setSelectedTreeType(type)}
                      disabled={!(currentPaymentPlan?.value >= type?.value)}
                      sx={{
                        backgroundColor:
                          selectedTreeType.value === type.value
                            ? "#1D77B7"
                            : "white",
                        color:
                          selectedTreeType.value === type.value
                            ? "white"
                            : "black",
                        border:
                          selectedTreeType.value !== type.value
                            ? "1px solid #E7E7E7"
                            : "1px solid #1D77B7",
                        "&:hover": {
                          backgroundColor:
                            selectedTreeType.value === type.value && "#1D77B7",
                          boxShadow: "none",
                        },
                      }}
                    >
                      {!(currentPaymentPlan?.value >= type.value) && (
                        <img
                          src={lockIcon}
                          alt="lock"
                          width="18px"
                          height="22px"
                          style={{
                            position: "absolute",
                            top: "-10px",
                            right: 0,
                            padding: 0,
                          }}
                        />
                      )}
                      {type.label}
                    </Button>
                  </span>
                </Tooltip>
              ))}
            </Box>
            <div className="upgradeContainer">
              <NavLink
                onClick={onUpdateClick}
                className="upgrade"
                style={{
                  display:
                    (isMobile && currentPaymentPlan?.value < 3) || showUpgrade
                      ? "block"
                      : "none",
                }}
              >
                Upgrade To Premium
                <img
                  src={rightIcon}
                  alt="right"
                  width="8px"
                  height="8px"
                  style={{
                    marginLeft: 4,
                  }}
                />
              </NavLink>
            </div>
          </div>
          <Box className="actionNameDescription">
            <Typography
              sx={{
                fontFamily: "ClashDisplay-medium",
                padding: "20px 0px 10px 0px",
              }}
            >
              Action or Event
            </Typography>
            <Controller
              control={control}
              name="event"
              rules={{
                required: "Enter an action or event",
              }}
              render={({ field }) => (
                <TextField
                  inputProps={{
                    style: {
                      fontFamily: "ClashDisplay-Regular",
                      fontSize: isMobile ? 12 : 14,
                      padding: "10px",
                    },
                  }}
                  fullWidth
                  placeholder="Describe your action or event"
                  helperText={errors.event?.message}
                  error={Boolean(errors.event)}
                  onChange={() => handleFieldChange("event")}
                  {...field}
                />
              )}
            />
          </Box>
          <Box className="actionNameContext">
            <Typography
              sx={{
                fontFamily: "ClashDisplay-medium",
                padding: "30px 0px 10px 0px",
              }}
            >
              Context
            </Typography>
            <Controller
              control={control}
              name="context"
              rules={{
                required: "Enter context",
              }}
              render={({ field }) => (
                <TextField
                  inputProps={{
                    style: {
                      fontFamily: "ClashDisplay-Regular",
                      fontSize: isMobile ? 12 : 14,
                    },
                  }}
                  fullWidth
                  placeholder="Add context to your action or event"
                  multiline
                  minRows={4}
                  onChange={() => handleFieldChange("context")}
                  {...field}
                  helperText={errors.context?.message}
                  error={Boolean(errors.context)}
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            type="submit"
            sx={{
              backgroundColor: "#1D77B7",
              textTransform: "none",
              fontFamily: "ClashDisplay-Regular",
              margin: "20px 0px 30px 0px",
              "&:hover": {
                backgroundColor: "#1D77B7",
                boxShadow: "none",
              },
            }}
            variant="contained"
          >
            {selectedUpdateTreeId ? "Regenerate" : "Generate"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
